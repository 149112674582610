import React from "react";
import "./css/style.css"
import './css/font-awesome.css';
import Router from "./Router";


function App() {
  return (
    <Router />
  );
}

export default App;
